import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ConnectLoyaltyCards from './ConnectLoyaltyCards';
import DateOfBirthQuestion from './DateOfBirthQuestion';
import LoyaltyCardsQuestion from './LoyaltyCardsQuestion';
import DemographicQuestions from './DemographicQuestions';
import CardsSharingQuestion from './CardsSharingQuestion';
import ShoppingFoodCategoryQuestion from './ShoppingFoodCategoryQuestion';
import MeatQuestion from './MeatQuestion';
import AllergiesQuestion from './AllergiesQuestion';
import ActivityLevelQuestion from './ActivityLevelQuestion';
import EducationQuestion from './EducationQuestion';
import IncomeQuestion from './IncomeQuestion';
import SportsQuestion from './SportsQuestion';
import DisordersQuestion from './DisordersQuestion';
import ShoppingMigrosQuestion from './ShoppingMigrosQuestion';
import ShoppingCoopQuestion from './ShoppingCoopQuestion';
import ShoppingQuestion from './ShoppingQuestion';
import OtherAppsQuestion from './OtherAppsQuestion';
import UserSignUp from './UserSignUp';
import ControlGroupSignUp from './ControlGroupSignUp';
import SignUpLink from './SignUpLink';
import SelectDataCollectionMethod from './SelectDataCollectionMethod';
import DatafetchingStarted from './DatafetchingStarted';
import NextSteps from './NextSteps';

import SurveySuccessPage from '../../pages/SurveySuccessPage';
import { signUpService } from '../../services/Authentication/useAuth';
import SurveyInfoPage from '../../pages/SurveyInfoPage';
import { scraperService } from '../../services/Scraper/scraperService';

export class MultiStepSignUpSurvey extends Component {
    constructor(props) {
        super(props);

        // delete user from localstorage if token is stored
        if (!this.props.completeRegistration) localStorage.removeItem('user');
        let controlGroup = Math.random() < 0.5;
        let step = 0;
        // let datafetchingMethod = '';
        let loyaltyCards = '';
        if (this.props.completeRegistration) {
            // get user
            let user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                if (user.type === 'Control') controlGroup = true;
                else if (user.type === 'Experiment') controlGroup = false;
            } else {
                // get JWT token from URL
                const jwtToken = new URLSearchParams(window.location.search).get('token');
                if (jwtToken) {
                    console.log('JWT token found in URL');
                    // get userType from JWT token
                    const decodedToken = jwtToken.split('.')[1];
                    const decoded = JSON.parse(window.atob(decodedToken));
                    if (decoded.userType === 'Control') controlGroup = true;
                    else if (decoded.userType === 'Experiment') controlGroup = false;
                }
            }
            step = 5.5;
            // datafetchingMethod = 'HSG';
            // too much effort to get the loyalty cards from the user and we have the data anyway
            loyaltyCards = 'both';
        }

        this.state = {
            step: step,
            // change this to integrate BAM again
            // datafetchingMethod: datafetchingMethod,
            dataFetchingMethod: 'HSG',
            openMigrosDialog: false,
            openCoopDialog: false,
            controlGroup: (this.props.referrer === 'SwissRe' | this.props.referrer === 'FoodCoach') ? false : controlGroup,
            credentials: {
                email: '',
                password: '',
                passwordConfirm: '',
                migrosEmail: '',
                migrosPassword: '',
                coopEmail: '',
                coopPassword: '',
            },
            survey: {
                referrer: this.props.referrer ? this.props.referrer : null,
                userType: '',
                birthdate: new Date(),
                loyaltyCards: loyaltyCards,
                height: '',
                weight: '',
                bmi: 0,
                gender: '',
                percShoppingMigros: '',
                percShoppingCoop: '',
                usageMigros: '',
                usageCoop: '',
                percFruits: '',
                percVegetables: '',
                percProteinFoods: '',
                percProcessedFoods: '',
                percCarbs: '',
                percOils: '',
                percBeverages: '',
                loyaltyShareAdults: '',
                loyaltyShareKids: '',
                loyaltyShareTeens: '',
                meat: '',
                historyUsingApps: '',
                historyApps: '',
                currentlyUsingOtherApps: '',
                currentOtherApps: '',
                allergiesAndAbstentions: { allergies: [], abstentions: [] },
                disorders: [],
                otherDiseases: '',
                activityLevelAtWork: '',
                sports: '',
                education: '',
                householdIncome: '',
            },
        };
    }

    handleChange = (key, value) => {
        this.setState({ [key]: value });
    };

    handleSurveyChange = (key, value, callback = undefined) => {
        // create a copy of state and change the value of key
        const updateState = { ...this.state.survey, [key]: value };
        if (callback === undefined) {
            this.setState({ survey: updateState });
        } else {
            this.setState({ survey: updateState }, () => {
                callback(this.state.survey[key]);
            });
        }
    };

    handleAuthChange = (keysValues) => {
        const updateState = { ...this.state.credentials, ...keysValues };
        let loyaltyCards = '';
        if (updateState.migrosEmail && !updateState.coopEmail) {
            loyaltyCards = 'migros';
        } else if (!updateState.migrosEmail && updateState.coopEmail) {
            loyaltyCards = 'coop';
        } else if (updateState.migrosEmail && updateState.coopEmail) {
            loyaltyCards = 'both';
        }

        this.setState({ credentials: updateState, survey: { ...this.state.survey, loyaltyCards: loyaltyCards } });
    };

    submitRegistrationMin = async () => {
        await signUpService.sendRegistrationMin(this.state.credentials, this.state.controlGroup).then((user) => {
            if (user !== null) {
                this.setState({ step: this.state.step + 1 });
                console.log('Registration data for user got filled out and sent.');
            } else alert(this.props.t('something_went_wrong'));
        });
    };

    submitCompleteRegistration = () => {
        const data = { ...this.state };
        ['percShoppingMigros', 'percShoppingCoop', 'usageMigros', 'usageCoop'].forEach((e) => {
            if (data.survey[e] === '') data.survey[e] = -1;
        });
        if (data.controlGroup) {
            data.survey['userType'] = 'Control';
        } else {
            data.survey['userType'] = 'Experiment';
        }

        const jwtToken = new URLSearchParams(window.location.search).get('token');
        signUpService.sendCompleteRegistration(data.survey, jwtToken).then((res) => {
            if (res === true) {
                this.setState({ step: this.state.step + 3 });
                console.log('Complete registration data for user got filled out and sent.');
            } else alert(this.props.t('something_went_wrong'));
        });
    };

    // submit signup / survey data to backend
    submitData = () => {
        const data = { ...this.state };

        if (data.controlGroup) {
            data.survey['userType'] = 'Control';
        } else {
            data.survey['userType'] = 'Experiment';
        }

        // delete data that should not be sent to backend
        delete data['controlGroup'];
        delete data.credentials['passwordConfirm'];

        // // add -1 values for missing survey data
        ['percShoppingMigros', 'percShoppingCoop', 'usageMigros', 'usageCoop'].forEach((e) => {
            if (data.survey[e] === '') data.survey[e] = -1;
        });

        signUpService.sendSignUpSurvey(data).then((user) => {
            if (user !== null) {
                this.setState({ step: this.state.step + 1 });
                console.log('Sign up survey for user got filled out and sent.');
                // next step
            } else alert(this.props.t('something_went_wrong'));
        });
    };

    // handle number inputs in the survey
    handleNumberInput = (value) => {
        if (isNaN(parseInt(value))) return '';
        return parseInt(value);
    };

    render() {
        const { controlGroup } = this.state;
        const values = this.state.survey;
        const authValues = this.state.credentials;

        // Each step defines the survey page that should be displayed
        switch (this.state.step) {
            // case 0:
            //     return <SurveyWelcomePage nextStep={() => this.setState({ step: this.state.step + 1 })} />;
            case 0:
                return (
                    <DateOfBirthQuestion
                        // change this to integrate BAM again
                        // nextStep={() => this.setState({ step: this.state.step + 1 })}
                        nextStep={() => this.setState({ step: this.state.step + 2 })}
                        // prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                        datafetchingMethod={this.state.datafetchingMethod}
                        completeRegistration={false}
                    />
                );
            case 1:
                return (
                    <SelectDataCollectionMethod
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleChange}
                    />
                );
            case 2:
                return (
                    <NextSteps
                        nextStep={(steps) => this.setState({ step: this.state.step + steps })}
                        // change this to integrate BAM again
                        // prevStep={() => this.setState({ step: this.state.step - 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 2 })}
                        dataFetchingMethod={this.state.dataFetchingMethod}
                    />
                );
            case 3:
                // this is only for HSG data collection method
                return (
                    <ConnectLoyaltyCards
                        nextStep={async () => {
                            await this.submitRegistrationMin();
                            scraperService.scraperRequest('POST', '/user/reset');
                        }}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleAuthChange}
                        authValues={authValues}
                        dataFetchingMethod={this.state.dataFetchingMethod}
                        controlGroup={this.state.controlGroup}
                    />
                );
            case 4:
                // this is only for HSG data collection method
                return <DatafetchingStarted nextStep={() => this.setState({ step: this.state.step + 2 })} />;
            case 5:
                // this is only for BAM data collection method
                return (
                    <LoyaltyCardsQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 3 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                        dataFetchingMethod={this.state.dataFetchingMethod}
                    />
                );
            case 5.5:
                return (
                    <DateOfBirthQuestion
                        nextStep={() => this.setState({ step: this.state.step + 0.5 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                        datafetchingMethod={this.state.datafetchingMethod}
                        completeRegistration={true}
                    />
                );
            case 6:
                return (
                    <DemographicQuestions
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={(step) => this.setState({ step: this.state.step - step })}
                        handleChange={this.handleSurveyChange}
                        handleNumberInput={this.handleNumberInput}
                        values={values}
                        completeRegistration={this.props.completeRegistration}
                    />
                );
            case 7:
                if (this.state.survey.loyaltyCards === 'both') {
                    return (
                        <ShoppingQuestion
                            nextStep={() => this.setState({ step: this.state.step + 1 })}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleSurveyChange}
                            handleNumberInput={this.handleNumberInput}
                            values={values}
                        />
                    );
                }
                if (this.state.survey.loyaltyCards === 'migros') {
                    return (
                        <ShoppingMigrosQuestion
                            nextStep={() => this.setState({ step: this.state.step + 1 })}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleSurveyChange}
                            handleNumberInput={this.handleNumberInput}
                            values={values}
                        />
                    );
                }
                if (this.state.survey.loyaltyCards === 'coop') {
                    return (
                        <ShoppingCoopQuestion
                            nextStep={() => this.setState({ step: this.state.step + 1 })}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleSurveyChange}
                            handleNumberInput={this.handleNumberInput}
                            values={values}
                        />
                    );
                }
                break;
            case 8:
                return (
                    <ShoppingFoodCategoryQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        handleNumberInput={this.handleNumberInput}
                        values={values}
                    />
                );
            case 9:
                return (
                    <CardsSharingQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        handleNumberInput={this.handleNumberInput}
                        values={values}
                    />
                );
            case 10:
                return (
                    <MeatQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 11:
                return (
                    <AllergiesQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 12:
                return (
                    <OtherAppsQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 13:
                return (
                    <ActivityLevelQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 14:
                return (
                    <SportsQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 15:
                return (
                    <DisordersQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 16:
                return (
                    <EducationQuestion
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                    />
                );
            case 17:
                return (
                    <IncomeQuestion
                        submitData={this.submitData}
                        nextStep={() => {
                            if (this.state.dataFetchingMethod === 'HSG') {
                                this.submitCompleteRegistration();
                            } else {
                                this.setState({ step: this.state.step + 1 });
                            }
                        }}
                        prevStep={() => this.setState({ step: this.state.step - 1 })}
                        handleChange={this.handleSurveyChange}
                        values={values}
                        dataFetchingMethod={this.state.dataFetchingMethod}
                    />
                );
            case 18:
                if (controlGroup) {
                    return (
                        <ControlGroupSignUp
                            nextStep={this.submitData}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleAuthChange}
                            values={authValues}
                        />
                    );
                } else {
                    return (
                        <UserSignUp
                            nextStep={this.submitData}
                            prevStep={() => this.setState({ step: this.state.step - 1 })}
                            handleChange={this.handleAuthChange}
                            values={authValues}
                        />
                    );
                }
            case 19:
                return (
                    <SignUpLink
                        nextStep={() => this.setState({ step: this.state.step + 1 })}
                        controlGroup={controlGroup}
                        values={values}
                    />
                );
            case 20:
                return <SurveySuccessPage controlGroup={controlGroup} />;
            default:
                return <SurveyInfoPage />;
        }
    }
}

export default withTranslation()(MultiStepSignUpSurvey);
